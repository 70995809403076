import useSWR from "swr";

import { AskRequest, AskResponse, ChatRequest } from "./models";

export function useUser() {
    const fetcher = (url: any) =>
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                args: {},
                app_id: "kb"
            })
        }).then(response => response.json());

    const url = `${import.meta.env.VITE_CONTROL_PANEL_BASE_URL_ADAPTIVE}/api/kb_profile`;
    const { data, error, isLoading } = useSWR(url, fetcher);

    if (error) {
        return {
            userData: undefined,
            userLoading: isLoading,
            userError: error
        };
    }

    if (!data) {
        return {
            userData: undefined,
            userLoading: isLoading,
            userError: error
        };
    }

    if (data.status !== "ok") {
        return {
            userData: undefined,
            userLoading: isLoading,
            userError: `#${data.code} ${data.data.error_type}: ${data.data.error_details}`
        };
    }

    return {
        userData: data.data,
        userLoading: isLoading,
        userError: undefined
    };
}

export async function askApi(options: AskRequest): Promise<AskResponse> {
    const url = `${import.meta.env.VITE_API_BASE_URL}/ask`;

    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            question: options.question,
            approach: options.approach,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function chatApi(options: ChatRequest): Promise<AskResponse> {
    const url = `${import.meta.env.VITE_API_BASE_URL}/chat`;

    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                index: options.overrides?.index,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export function getCitationFilePath(citation: string): string {
    return `${import.meta.env.VITE_API_BASE_URL}/content/${citation}`;
}

export function getCitationFileName(citation: string): string {
    return `${import.meta.env.VITE_API_BASE_URL}/content/${citation}`.split("/").pop() ?? citation;
}
