import { Outlet, NavLink, Link } from "react-router-dom";
import styles from "./Layout.module.css";

const Layout = () => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <img
                            src="https://admin.bulbshare.com/assets/dashboard/images/bulbshare/bulbshare-logo-white.svg"
                            aria-hidden="true"
                            aria-label="Bulbshare"
                        />
                    </Link>
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
